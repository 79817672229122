import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Maki, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const ProductListing: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  const GOLIVE = new Date('2024-07-21T23:55:00+0100');

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    // <article className="py-5 product-listing">
    //   <Container className="list-page">
    //     <Helmet>
    //       <title>{t(`PRODUCTS.TITLE`)}</title>
    //     </Helmet>

    //     <Masonry
    //       id="product-listing"
    //       rowClassName="card-columns"
    //       columnClassName=""
    //       products={products}
    //       filters="off"
    //       showSubtitle
    //     />
    //     {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
    //   </Container>
    // </article>

    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page inverse-navbar" />
      </Helmet>

      {/* {new Date() >= GOLIVE ? (
        <Container fluid className="jumbohead mb-5">
          <Container className="h-100">
            <a href="/product/72638/der-mann-im-wald-streaming-box">
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                  <img src={require('./assets/images/miw_typo.png')} className="img-fluid w-100" alt="DER MANN IM WALD - Die limitierte Streaming-Box" />
              </div>
            </a>
          </Container>
        </Container>
      ) : (
        <Container fluid className="">
          <h1 className="text-center mt-5 superhead">Merch</h1>
        </Container>
      )} */}

      <Container fluid className="">
        <h1 className="text-center mt-5 superhead">Merch</h1>
      </Container>

      <Container className="list-page py-5">
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter(productFilter)}
            type={Maki}
            filters="off"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
