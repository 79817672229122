import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api/products';
import {
  Container,
  NativeSlider,
  VariantChooser,
  useShopConfig,
  ContentrArea,
  useProductDetailLink,
} from '@ttstr/components';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  return (
    <article className="product-detail mt-5" itemScope itemType="http://schema.org/Product">
      <Helmet>
        <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
        <body className="product-detail-page inverse-navbar" />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="6">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
              />
            </Col>
            <Col lg="6" className="text-center text-lg-left">
              <header>
                {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
                <h1 className="product-detail-title">
                  <span itemProp="name">{product.title}</span>
                  {product.subtitle && <small>{product.subtitle}</small>}
                </h1>
              </header>
              <ContentrArea id="before-variants" />
              <VariantChooser className="mb-4" product={product} />
              <ContentrArea id="after-variants" />
              {product.description && (
                <>
                  {/* <strong>{t(`PRODUCT.INFORMATION`)}</strong> */}
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </>
              )}
              <ContentrArea id="general-info" />
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
