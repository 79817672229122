import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, useIntl, ContentrArea } from '@ttstr/components';

import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import Masonry from './Masonry';

const TicketListingHidden: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing">
      {/* <Container fluid className='ticket-header-image' /> */}

      <Container fluid className="jumbohead tour mb-5">
        <Container className="h-100">
          <div className="h-100 w-100 d-flex justify-content-center align-items-end">
            <img
              src={require('./assets/images/tour2025.png')}
              className="img-fluid w-100"
              alt="MORD AUF EX - TRUE CRIME TOUR 2025"
            />
          </div>
        </Container>
      </Container>

      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
          <body className="ticket-listing-page" />
        </Helmet>

        <h1 className="text-center mb-4 d-none">{t(`TICKETLISTING.TITLE`)}</h1>

        <ContentrArea id="above-listing" />

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets.filter((t) => t.tag_ids.includes(8114))}
            type="list"
            showCity
            showLocation
          />
        )}

        <ContentrArea id="below-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListingHidden);
